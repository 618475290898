import {SiFacebook, SiGithub, SiGitlab, SiHashnode, SiLinkedin} from "react-icons/si";
import {BsMedium} from "react-icons/bs";
import avatarImage from "../assets/img/avater.jpg"

export const BasicInfoShema = {
  profile: {
    name: "Tanvir Mohammad Rizvy",
    occupation: "FullStack Software Engineer",
    location: "Bysl Global Technologies Limited Plot 39/2, Kalachandpur (North Baridhara), Gulshan, Dhaka 1212, Bangladesh",
    image: avatarImage,
  },
  contact:
    {
      email: "tanvirmohammad2@gmail.com",
    mobileNumber: "+880 1830316313",
    address: "Dhaka, Bangladesh",
    },
  aboutMe: {

    description: `
      <div>
      <h2>Hi, I'm Rizvy</h2>
      <p>In my capacity as an Executive Software Engineer at BYSL Global Technology Group, I am entrusted with steering the development of high-performance customer-facing applications in our dynamic tech environment. My primary focus centers on meticulously crafting robust and scalable systems, with a strong emphasis on microservices architecture.</p>

      <p>One of my notable contributions involves spearheading the development of a cutting-edge HRMS application. Employing technologies such as ReactJS, Redux-saga, React Hooks, React-Router, JavaScript, Laravel, MySql I ensured the creation of a sophisticated system tailored to meet the intricate demands of our users.</p>
      
      <p>Working closely with UI/UX designers, I played a pivotal role in creating and maintaining a React Material-UI based web application. This involved an ongoing commitment to ensuring that the interfaces are not only responsive but also user-friendly, aligning with the highest design standards.</p>
      
      <p>In addition to these endeavors, I led the development of a high-performance e-commerce application. My approach involved creating multi-browser compatible pages using Angular, Ngrx, and Angular Routing, Nodejs, Nextjs ensuring a seamless and engaging online shopping experience.</p>
      
      <p>My expertise extends to implementing frontend functionalities with React.js, guaranteeing optimal performance and delivering a user experience that meets the highest standards. Moreover, I have been actively involved in the development and maintenance of responsive web applications using Angular, ensuring they consistently deliver high performance and cross-browser compatibility.</p>
      
      <p>A key aspect of my role involves the integration of frontend components with Laravel backend services. This seamless integration allows for effective management of data and business logic, creating a cohesive and efficient system.</p>
      
      <p>Collaborating closely with the backend team, I have been instrumental in designing and implementing RESTful APIs using Node.js. This facilitates seamless communication between the frontend and backend systems, a critical element in our pursuit of technological excellence.</p>
      
      <p>My commitment to Agile development processes has been unwavering, with active participation in daily stand-ups, sprint planning, and retrospectives. This ensures the timely delivery of features, meeting project deadlines and maintaining a high level of adaptability in our approach.</p>
      
      <p>Beyond development, I take pride in conducting thorough code reviews, debugging sessions, and performance optimizations. This ongoing commitment is aimed at maintaining not just the functionality but also the quality and scalability of our codebase.</p>
      </div>
    `
  },
  academic: [
   {
      career: "B.Sc. in Computer Science & Engineering",
      passingYear: "2020",
      institution: "Daffodil International University",
    },
  ],
  interest: ["Open Source Software", "Travelling", "Cloud Computing"],
  skills: {
    technicalLabel: "Technologies",
    softLabel: "Skills",
    technicalSkills: [
      "React",
      "Angular",
      "TypeScript",
      "JavaScript (ES6+)",
      "PHP",
      "Laravel",
      "MySql",
      "MongoDb",
      "Git",
    ],
    softSkills: [
      "UX",
      "KeyCloak",
      "Docker",
      "Mobile Devices & Applications",
      "Product Management & Marketing",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      // {
      //   label: "Medium",
      //   name: "hashnode",
      //   url: "https://medium.com/",
      //   icon: <BsMedium size={25} fill="bg-secondary"/>
      // },
      // {
      //   label: "HashNode",
      //   name: "hashnode",
      //   url: "https://hashnode.com/",
      //   icon: <SiHashnode size={25}/>
      // },
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://www.linkedin.com/in/tanvirmohammad2/",
        icon: <SiLinkedin size={25}/>
      },
      {
        label: "GitHub",
        name: "github",
        url: "https://github.com/tanvirmohammadrizvy",
        icon: <SiGithub size={25} fill="bg-secondary"/>,
      },
      {
        label: "FaceBook",
        name: "facebook",
        url: "https://www.facebook.com/tanvirmohammad2",
        icon: <SiFacebook size={25}/>
      },
    ],
  }
};

export const ExperienceSchema = [
  {
    title: "Executive, Software Engineer",
    period: "Oct 2022 - Present",
    company: "BYSL Global Technology Group",
    description: ["Develop high-performance customer-facing HRMS application using ReactJS, Redux-saga, React Hooks, React-Router, JavaScript, Laravel, MySql.",
    "Developed and maintained a React Material-UI based web application, collaborating with UI/UX designers to create responsive and user-friendly interfaces.",
    "Develop high-performance customer-facing e-commerce application multi-browser compatible pages using Angular, Ngrx, Angular Routing, Nodejs, Nextjs.",
    "Implemented frontend functionalities using React.js, ensuring high performance and seamless user experiences.",
    "Developed and maintained responsive web applications using Angular, ensuring high performance and cross-browser compatibility.",
    "Integrated the frontend with Laravel backend services to handle data management and business logic.",
    "Collaborated with the backend team to design and implement RESTful APIs using Node.js, facilitating communication between frontend and backend systems.",
    "Participated in Agile development processes, including daily stand-ups, sprint planning, and retrospectives, to deliver features on time and meet project deadlines.",
    "Conducted code reviews, debugging sessions, and performance optimizations to maintain code quality and enhance application scalability."],
  },
  {
    title: "Assistant Software Engineer",
    period: "Feb 2021 - Oct 2022",
    company: "Orpa Communication",
    description: ["Developed microservices using React for the frontend, ensuring modularity and encapsulation of functionalities to promote reusability and ease of maintenance.",
    "Played a key role in the development of various web applications using React, Angular, and Node.js, contributing to both frontend and backend components.",
    "Integrated the frontend with Laravel backend services to handle data management and business logic.",
    "Collaborated closely with team members in design discussions, code reviews, and problem-solving sessions to ensure project success and code quality.",
    "Took initiative in troubleshooting technical issues and implementing solutions to resolve bugs and improve system stability.",
    "Actively pursued continuous learning and professional development opportunities to stay updated with industry trends and best practices.",
    "Participated in Agile development processes, including daily stand-ups, sprint planning, and retrospectives, to deliver features on time and meet project deadlines.",
    "Used: React, Angular, TypeScript, JavaScript (ES6+), PHP, Laravel, NodeJs, NestJs, MySQL, MongoDb, Keycloak, Docker, Gitlab CI, etc."],
  },
  {
    title: "Junior Developer",
    period: "Jan 2020 - Jan 2021",
    company: "Tech Innovations Ltd., Cityville, Country",
    description: ["Developed Crud application using ReactJS, Redux-saga, React Hooks, React-Router, JavaScript, HTML5, CSS3, SASS, Bootstrap4.",
    "Developed Crud application multi-browser compatible pages using HTML5, CSS3, SASS, Angular, Ngrx, Angular Routing.",
    "Collaborated closely with team members in design discussions, code reviews, and problem-solving sessions to ensure project success and code quality.",
    "Contributed to the development of various web applications using React, Angular, and Node.js, collaborating with senior developers to implement frontend and backend functionalities.",
    "Assisted in troubleshooting and resolving technical issues, performing debugging and testing to identify and fix bugs."],
  },
];